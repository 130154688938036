<template>
  <router-view />
</template>

<script>
  import * as faceapi from '@vladmandic/face-api'
  import initImageSrc from "@/assets/init.jpg"
  import Api from '@/api'
  export default {
    mounted() {
      Api.tokenValid().then(ok=>{
        if(!ok){
          console.log("clear access token")
          localStorage.removeItem("access-token")
        }
        this.$store.dispatch('refreshTokenIfNeeded');
      })
      this.tokenRefresher = window.setInterval(() => {
        this.$store.dispatch('refreshTokenIfNeeded');
      }, 5 * 60 * 1000);
      if(localStorage.getItem("initFaceApi") == "true"){
        this.initFaceApi()
      }
      
      document.addEventListener("fullscreenchange", () => {
          this.$store.dispatch("fullscreenChange")
      });
    },
    methods: {
      async initFaceApi() {
          let modelPath = "model"
          await faceapi.nets.tinyFaceDetector.load(modelPath);
          await faceapi.nets.faceLandmark68TinyNet.loadFromUri(modelPath)
          await faceapi.nets.faceRecognitionNet.loadFromUri(modelPath)
          const initImage = await faceapi.fetchImage(initImageSrc)
          await faceapi.detectAllFaces(initImage, new faceapi.TinyFaceDetectorOptions())
              .withFaceLandmarks(true)
              .withFaceDescriptors();
          console.log("Init done")
          this.$store.commit("faceApiIsReady")
      }
    }
  }
</script>

<style>
  @import '@/assets/global.css' 
  
</style>
